import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl } from "gatsby-plugin-react-intl"

const HeroFeatures = ({ intl, theme, content }) => (
    <div className={`hero-features ` + theme}>
        <div className={`clipped-area`}></div>
        <div className={`non-clipped-area`}>
            <Container>
                <Row>
                    <Col md={8}>
                        <h1>{content.title}</h1>
                        <div className={`text`} dangerouslySetInnerHTML={{ __html: content.text }}></div>
                    </Col>
                    <Col md={4}></Col>
                </Row>
            </Container>
        </div>
    </div>
)

export default injectIntl(HeroFeatures)
