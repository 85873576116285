import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import {injectIntl, Link} from "gatsby-plugin-react-intl";
import ImageFluid from "./image-fluid";



function MultiColBlock({ intl, content }) {

    return(
        <div className={`multi-col-block`}>
            {content.intro &&
                <Row>
                    <Container>
                        <div className={`intro`}>
                            <div className={`title`}>{content.intro.title}</div>
                            <div className={`text`} dangerouslySetInnerHTML={{__html: content.intro.text}}></div>
                        </div>
                    </Container>
                </Row>
            }
            <Row>
                {content.blocks.map((block, i) => {
                    return(
                        <Col lg key={i}>
                            <div className={`content ` + block.alignment}>
                                {block.image &&
                                <div className={`image`}><ImageFluid alt={block.title} filename={block.image} /></div>
                                }
                                {block.title &&
                                <div className={`title`}>{block.title}</div>
                                }
                                {block.subtitle &&
                                <div className={`subtitle`}>{block.subtitle}</div>
                                }
                                {block.text &&
                                <div className={`text`} dangerouslySetInnerHTML={{__html: block.text}}></div>
                                }
                                {block.button &&
                                <Link className={`btn btn-lg button btn-` + block.button.theme} to={block.button.url}>
                                    {block.button.label}
                                </Link>
                                }
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </div>

    )

}

export default injectIntl(MultiColBlock)

